<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="schedule_headers"
        :items="result"
        hide-default-footer
        mobile-breakpoint="0"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
export default {
  name: "schedule-detail",
  props: {
    schedule_id: {
      type: Number,
      default: 0,
    },
  },
  mixins: [HttpCommonMixin],
  data(vm) {
    return {
      result: [],
      schedule_headers: [
        {
          text: "Init",
          value: "Init",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "InPool",
          value: "InPool",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Packed",
          value: "Packed",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Processing",
          value: "Processing",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Done",
          value: "Done",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Fail",
          value: "Fail",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Total",
          value: "Total",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "List",
          value: "List",
          class: "text-secondary font-weight-bolder opacity-7 secondary",
          sortable: false,
        },
        {
          text: "Transcript",
          value: "Transcript",
          class: "text-secondary font-weight-bolder opacity-7 secondary",
          sortable: false,
        },
        {
          text: "ListAndTranscript",
          value: "ListAndTranscript",
          class: "text-secondary font-weight-bolder opacity-7 secondary",
          sortable: false,
        },
        {
          text: "CopCreditorDiff",
          value: "CopCreditorDiff",
          class: "text-secondary font-weight-bolder opacity-7 secondary",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getRemoteData();
  },
  methods: {
    getRemoteData() {
      console.log("get remote data", this.schedule_id, "...");
      if (this.schedule_id == null || this.schedule_id == 0) {
        return;
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/${this.schedule_id}/remote`;

      const params = {};
      this.doHttpGet(url, params, this.setResult, null, this.onFinal);
    },
    setResult(data) {
      console.log("set result", data);
      this.result = [data.result];
      console.log("set result", this.result);
    },
    // TODO Create schedule, remove schedule, unpack schedule
    editItem(item) {
      console.log("edit item", this.schedule_id);
    },
  },
};
</script>
