<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col cols="12">
        <v-card class="card-shadow border-radius-xl">
          <v-card-title><h3>Remote Schedule Admin</h3></v-card-title>
          <v-dialog v-model="confirm_dialog" scrollable width="40%">
            <v-card>
              <v-card-title
                ><h2>{{ getConfirmTitle() }}</h2></v-card-title
              >
              <v-card-text
                ><h3>{{ getConfirmMessage() }}</h3>
                ></v-card-text
              >

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-secondary
                    px-6
                    py-2
                    mt-2 mt-8
                  "
                  @click="confirm_dialog = false"
                >
                  {{ $t("Close") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    bg-gradient-primary
                    py-5
                    px-6
                    mt-6
                    mb-2
                    me-2
                    ms-auto
                    text-white
                  "
                  @click="onActionConfirmed()"
                >
                  {{ $t("Run") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card class="card-shadow border-radius-xl mt-6">
            <v-card-text class="px-4 py-0">
              <v-data-table
                :headers="schedule_headers"
                :items="orders"
                :search="search"
                class="table"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
                mobile-breakpoint="0"
                :options.sync="options"
                :server-items-length="items_count"
                multi-sort
                :loading="loading"
                :show-expand="!isHideExpand"
                :single-expand="true"
              >
                <template v-slot:loading>
                  {{ $t("Loading...") }}
                </template>
                <template v-slot:top>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        hide-details
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        v-model="search"
                        placeholder="Search"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >fas fa-search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.create_time="{ item }">
                  {{ item.create_time | dateFormatDash }}
                </template>
                <template v-slot:item.valid="{ item }">
                  {{ item.valid | dateFormatDash }}
                </template>
                <template v-slot:item.status="{ item }"
                  >{{ item.status | getText(schedule_status_enum_str) }}
                </template>
                <template v-slot:item.type="{ item }"
                  >{{ item.type | getText(schedule_type_enum_str) }}
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td :colspan="12" class="px-2">
                    <ScheduleDetail :schedule_id="item.id"></ScheduleDetail>
                  </td>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        size="25"
                        v-on="on"
                        :disabled="item.status == schedule_status_enum.Unknown"
                        :color="
                          item.status > schedule_status_enum.Unknown
                            ? 'primary'
                            : 'grey'
                        "
                        @click="removeSchedule(item)"
                        >mdi-close</v-icon
                      >
                    </template>
                    <span>{{ $t("Remove Schedule") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        size="25"
                        v-on="on"
                        @click="createSchedule(item)"
                        :disabled="item.status != schedule_status_enum.Unknown"
                        :color="
                          item.status == schedule_status_enum.Unknown
                            ? 'primary'
                            : 'grey'
                        "
                        >mdi-plus</v-icon
                      >
                    </template>
                    <span>{{ $t("Create Schedule") }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <v-row>
                <v-col cols="6" class="ml-auto d-flex justify-end">
                  <v-pagination
                    prev-icon="fa fa-angle-left"
                    next-icon="fa fa-angle-right"
                    class="pagination"
                    color="#cb0c9f"
                    v-model="page"
                    :length="getPageCount()"
                    circle
                    :total-visible="15"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import CommonUtility from "@/util/CommonUtility.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import ScheduleDetail from "./ScheduleDetail.vue";

import {
  schedule_status_enum,
  schedule_status_enum_str,
  schedule_type_enum,
  schedule_type_enum_str,
} from "@/definitions.js";

export default {
  props: {
    history_type: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  name: "schedule-table",
  components: {
    ScheduleDetail,
  },
  data(vm) {
    return {
      isHideExpand: false,
      loading: false,
      schedule_status_enum: schedule_status_enum,
      schedule_status_enum_str: schedule_status_enum_str,
      schedule_type_enum: schedule_type_enum,
      schedule_type_enum_str: schedule_type_enum_str,
      page: 1,
      pageCount: 0,
      items_count: 0,
      itemsPerPage: 10,
      orders: [],
      options: {},
      search: "",
      schedule_headers: [
        {
          text: "ID",
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Start Date",
          value: "start_date",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "End Date",
          value: "end_date",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Type",
          value: "type",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "create time",
          value: "create_time",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      confirm_dialog: false,
      edit_item: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  watch: {
    // page() {
    //   console.log("page changed");
    //   this.getData();
    // },
    options() {
      console.log("options changed");
      this.getData();
    },
  },
  mixins: [HttpCommonMixin],
  methods: {
    ...mapGetters(["getToken"]),
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    getEndDate(date_str) {
      return date_str;
    },
    onFinal() {
      this.loading = false;
    },
    getData() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy.length > 0) {
        const sortParas = CommonUtility.getSortParas(sortBy, sortDesc);
        params.ordering = sortParas;
      }
      this.loading = true;
      this.doHttpGet(url, params, this.setResult, null, this.onFinal);
    },

    setResult(data) {
      console.log(data.items);
      this.items_count = data.total;
      this.orders = data.items;
    },
    edit(item) {
      console.log("edit item ", item);
    },
    onCommitInvoice(data) {
      const message = data.message;
      alert(message);
      this.edit_item = null;
      this.getData();
    },
    actionConfirm(item, allowConfirm) {
      if (allowConfirm == false) {
        return;
      }
      item["immediate"] = false;

      this.confirm_dialog = true;
      this.edit_item = item;
    },
    getConfirmTitle() {
      return "title";
    },
    getConfirmMessage() {
      return "message";
    },
    removeSchedule(item) {
      console.log("remove schedule", item.id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/${item.id}/remote`;
      this.doHttpDelete(url, this.setRemoveResult, null, this.onFinal);
    },
    setRemoveResult(data) {
      console.log(data);
      this.getData();
    },
    createSchedule(item) {
      console.log("create schedule", item.id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/${item.id}/remote`;
      this.doHttpPost(url, {}, this.setCreateResult, null, this.onFinal);
    },
    setCreateResult(data) {
      console.log("create schedule result", data);
      this.getData();
    },
  },
};
</script>
